
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Prop } from "vue-property-decorator";
import ModeratorSelector from "./moderator-selector.vue";
import draggable from "vuedraggable";
import SessionItemProfessionalAreaSelector from "./session-item-professional-area-selector.vue";
@Component({
  components: {
    draggable,
    ModeratorSelector,
    SessionItemProfessionalAreaSelector,
  },
})
export default class SessionItems extends Vue {
  @Prop() sessionItem!: any;
  @Prop() professionalAreaDataSource!: ListDataSource;
  @Prop() eventId!: number;
  @Prop() scheduleId!: number;
  @Prop() canEdit!: number;

  loaded: boolean = false;
  professionalAreaId: any = null;
  dataSource: ListDataSource = new ListDataSource({
    className: "EventSessionItemProfessionalArea",
    config: {},
  });
  items: any = [];

  editMode: boolean = false;
  $message: any;
  async created() {
    this.dataSource.items = this.sessionItem.eventSessionItemProfessionalAreas;
    this.loaded = true;
  }

  async add(professionalAreaId: number) {
    await this.dataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: this.scheduleId,
        eventSessionItemId: this.sessionItem.id,
        professionalAreaId,
      },
      true,
    );
    this.editMode = false;
    this.professionalAreaId = null;
    this.$message("Успешно добавлено");
  }
}
