
import { ListDataSource } from "@/data/List/ListDataSource";
import { Vue, Component, Prop } from "vue-property-decorator";
import SpeakerSelector from "./speaker-selector.vue";
import metaData from "./metaData.json";
import draggable from "vuedraggable";
@Component({
  components: { draggable, SpeakerSelector },
})
export default class SessionItems extends Vue {
  @Prop() personDataSource!: ListDataSource;
  @Prop() sessionItem!: any;
  @Prop() eventId!: number;
  @Prop() scheduleId!: number;
  @Prop() canEdit!: boolean;
  loaded: boolean = false;
  personId: any = null;
  editMode: boolean = false;
  dataSource: ListDataSource = new ListDataSource({
    className: "EventSessionItemPerson",
    config: {},
  });
  items: any = [];
  $message: any;

  async created() {
    this.dataSource.metadata = { properties: metaData.person };
    this.dataSource.items = this.sessionItem.eventSessionItemPersons;

    this.loaded = true;
  }

  async add(personId: number) {
    await this.dataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: this.scheduleId,
        eventSessionItemId: this.sessionItem.id,
        personId,
      },
      true,
    );
    this.personId = null;
    this.editMode = false;
    this.$message("Успешно добавлено");
  }
}
