
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import metaData from "./metaData.json";
import sessions from "./sessions.vue";
@Component({
  components: { sessions },
})
export default class ScheduleEditor extends Vue {
  @Prop() day!: any;
  @Prop() personDataSource!: ListDataSource;
  @Prop() professionalAreaDataSource!: ListDataSource;
  @Prop() editMode!: boolean;
  @Prop() eventId!: number;
  @Prop() schedule!: any;
  @Prop() activatable!: boolean;

  @Prop({ default: null }) braodcastId!: number | null;
  moment = moment;
  locationId = null;
  dataSource: ListDataSource = new ListDataSource({
    className: "EventDayLocation",
    config: {
      pageIndex: 1,
      pageSize: 200,
    },
  });

  locationsDataSource: ListDataSource = new ListDataSource({
    className: "EventLocation",
    config: {
      pageIndex: 1,
      pageSize: 200,
      filter: [new Filter("scheduleId", +this.schedule.id)].toFilterString(),
    },
  });

  async changeActiveSession(dayLocation: any, event: any) {
    await this.dataSource.updateField(dayLocation.id, [
      {
        fieldName: "activeSessionId",
        fieldValue: event.value ? event.sessionId : null,
      },
    ]);

    this.$emit("change-active-session", event.value);
  }

  get field() {
    return {
      id: "91432038-EE59-4391-BD4F-23D9820D9435",
      name: "eventBroadcastId",
      type: "string",
      description: null,
      caption: "Зал траснляций",
      editor: "broadcastselector",
      priority: 100,
      group: "general",
      visibleInTable: false,
      validations: [
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],

      config: {
        eventId: +this.eventId,
      },
    };
  }

  get filteredLocation() {
    if (!this.braodcastId) return this.dataSource.items;

    return this.dataSource.items.filter((dl: any) => {
      if (!this.braodcastId) return true;
      return dl.eventBroadcastId == +this.braodcastId;
    });
  }

  getDayLocation(id: number) {
    const finded = this.dataSource.items.find(
      (dl: any) => dl.eventLocationId == id
    );
    return finded;
  }

  loaded: boolean = false;
  $message: any;

  async created() {
    this.dataSource.items = this.day.eventDayLocations;
    this.locationsDataSource.items = this.schedule.eventLocations;
    this.locationsDataSource.metadata = { properties: metaData.location };
  }

  async add(locationId: number) {
    await this.dataSource.add(
      {
        eventId: +this.eventId,
        eventScheduleId: +this.schedule.id,
        eventDayId: this.day.id,
        eventLocationId: locationId,
      },
      true
    );
    this.locationId = null;

    this.$message("Успешно добавлено");
  }

  async addLocation() {
    try {
      const location = await this.locationsDataSource.add(
        {
          eventId: +this.eventId,
          eventScheduleId: +this.schedule.id,
          caption: `Новая локация ${this.locationsDataSource.items.length}`,
        },
        true
      );

      await this.add(location.id);
      this.$message("Успешно добавлено");
    } catch (e) {
      console.error((e as any)?.response?.data);
    }
  }

  async remove(item: any) {
    const hasSessions = item.eventLocationSessions.length > 0;
    if (hasSessions) {
      return this.$message(
        "Чтобы удалить локцию удалите все дочерние сесии",
        "error"
      );
    }
    await this.dataSource.remove(item.id);
    this.$message("Успешно удалено");
  }
}
